import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Image from '../components/image'

import styles from './about-us.module.css'
import PageLinks from '../components/page-links'
import Promotions from '../components/promotions'
import CenterTextSection from '../components/center-text-section'
import SEO from '../components/seo'

const IntroSection = ({ introSection: { montage, bodyHtml } }) => {
  const { firstImage, secondImage, thirdImage, fourthImage } = montage

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between">
      <div className={`${styles.montage} flex-1`}>
        <div className="flex flex-row">
          <div
            className={`${styles.firstMontageCol} flex-1 flex flex-col justify-center items-end`}
          >
            {firstImage ? (
              <Image
                className={`${styles.montageImage} ${styles.firstMontage}`}
                fluid={firstImage.childImageSharp.fluid}
              />
            ) : (
              ''
            )}
            {secondImage ? (
              <Image
                className={`${styles.montageImage} ${styles.secondMontage}`}
                fluid={secondImage.childImageSharp.fluid}
              />
            ) : (
              ''
            )}
          </div>
          <div
            className={`${styles.secondMontageCol} flex-1 flex flex-col-reverse items-start`}
          >
            {thirdImage ? (
              <Image
                className={`${styles.montageImage} ${styles.thirdMontage}`}
                fluid={thirdImage.childImageSharp.fluid}
              />
            ) : (
              ''
            )}
            {fourthImage ? (
              <Image
                className={`${styles.montageImage} ${styles.fourthMontage}`}
                fluid={fourthImage.childImageSharp.fluid}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div
        className={`${styles.description} flex-1`}
        dangerouslySetInnerHTML={{ __html: bodyHtml }}
      />
    </div>
  )
}

export const AboutUsTemplate = ({ page }) => {
  const {
    html,
    frontmatter: {
      title,
      introSection,
      otherSections,
      historySection,
      pageLinks,
    },
  } = page

  return (
    <>
    <SEO 
      title={"About The Projector Cinema, Story, History and People"}
      description={"Learn about the projector - The story of how it became the sleek, eclectic indie cinema you have come to love, the people and history of the Golden Mile Tower."}
    />
    <div className="container flex flex-col justify-between py-5 lg:py-20">
      <div className="mx-auto text-center w-full md:w-4/5 lg:w-3/5">
        <h1 className={styles.title}>{title}</h1>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <div className="mt-24 md:mt-32">
        <IntroSection introSection={introSection} />
      </div>
      <Promotions
        promotions={otherSections.map(o => ({ ...o, coverImage: o.image }))}
        alternateStyling={true}
        firstTextLeft={true}
        className={`${styles.promotions}`}
      />
      <div className="mt-24 md:mt-32">
        <CenterTextSection {...historySection} />
      </div>
      <div className="w-full mt-24">
        <PageLinks links={pageLinks} />
      </div>
    </div>
    </>
  )
}

const AboutUsPage = ({ data }) => {
  return <AboutUsTemplate {...data} />
}

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPage {
    page: markdownRemark(fields: { slug: { eq: "/about-us/" } }) {
      frontmatter {
        title
        introSection {
          montage {
            firstImage {
              childImageSharp {
                fluid(maxWidth: 326, maxHeight: 411, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            secondImage {
              childImageSharp {
                fluid(maxWidth: 185, maxHeight: 232, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            thirdImage {
              childImageSharp {
                fluid(maxWidth: 251, maxHeight: 316, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            fourthImage {
              childImageSharp {
                fluid(maxWidth: 399, maxHeight: 503, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          bodyHtml
        }
        otherSections {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 576, maxHeight: 384, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bodyHtml
          callToAction
          linkURL
        }
        historySection {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1160, maxHeight: 741, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bodyHtml
        }
        ...PageLinks
      }
      html
    }
  }
`
